import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from 'react';
import { Playground, Props } from 'docz';
import { Link, LinkVariant, Size, Space } from '@hse-design/react';
import { FigmaEmbed, ComponentLinks, P } from '../common';
import { colorBrandDark } from '@hse-design/tokens';
import { spacingSmall2X } from '@hse-design/tokens';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "link"
    }}>{`Link`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Link } from '@hse-design/react'
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=1705%3A4969'} storybook={'/?path=/story/typography-link--playground'} vueStorybook={'/?path=/story/typography-link--playground'} name={'Link'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Link – это компонент ссылки. Ссылки используются для переходов между разными страницами. Могут располагаться в тексте или самостоятельно.`}</p>
    <p>{`У компонента четыре варианта отображения: `}<inlineCode parentName="p">{`primary`}</inlineCode>{`, `}<inlineCode parentName="p">{`secondary`}</inlineCode>{`, `}<inlineCode parentName="p">{`secondaryInvert`}</inlineCode>{`, `}<inlineCode parentName="p">{`tertiary`}</inlineCode>{`. Вариант отображения задается пропом `}<inlineCode parentName="p">{`variant`}</inlineCode>{`.`}</p>
    <p><inlineCode parentName="p">{`primary`}</inlineCode>{` – вариант, используемый по умолчанию. Рекомендуем использовать в большом количестве текста`}</p>
    <p><inlineCode parentName="p">{`secondary`}</inlineCode>{` – данный вариант рекомендуется использовать самостоятельно, отдельно от текста`}</p>
    <p><inlineCode parentName="p">{`secondaryInvert`}</inlineCode>{` – рекомендуется использовать по тому же принципу, что и `}<inlineCode parentName="p">{`secondary`}</inlineCode>{`, только на цветном фоне`}</p>
    <p><inlineCode parentName="p">{`secondary`}</inlineCode>{` и `}<inlineCode parentName="p">{`secondaryInvert`}</inlineCode>{` ссылки можно подчеркнуть, передав проп `}<inlineCode parentName="p">{`underlined`}</inlineCode>{`.`}</p>
    <p>{`Ссылку можно сделать неактивной, передав проп `}<inlineCode parentName="p">{`inactive`}</inlineCode>{`. Такая ссылка становится недоступной для действий пользователя.`}</p>
    <p>{`По умолчанию посещенные ссылки никак не выделяются. Для изменения данного поведения передайте проп `}<inlineCode parentName="p">{`showVisited`}</inlineCode>{`.`}</p>
    <p>{`Компонент ссылки можно изменить, передав его в проп `}<inlineCode parentName="p">{`component`}</inlineCode>{`. По умолчанию используется тег `}<inlineCode parentName="p">{`a`}</inlineCode>{`.`}</p>
    <p>{`По умолчанию Link наследует текстовые стили. Вы можете передать проп `}<inlineCode parentName="p">{`standalone`}</inlineCode>{` для задания собственных стилей.`}</p>
    <Playground __position={1} __code={'<Link href=\"https://www.hse.ru/\" target=\"_blank\">\n  Национальный исследовательский университет «Высшая школа экономики»\n</Link>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      Link,
      LinkVariant,
      Size,
      Space,
      FigmaEmbed,
      ComponentLinks,
      P,
      colorBrandDark,
      spacingSmall2X,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Link href="https://www.hse.ru/" target="_blank" mdxType="Link">
    Национальный исследовательский университет «Высшая школа экономики»
  </Link>
    </Playground>
    <h2 {...{
      "id": "анатомия"
    }}>{`Анатомия`}</h2>
    <FigmaEmbed node={'?node-id=9662:34744'} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node={'?node-id=9662:34749'} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "вариант-отображения"
    }}>{`Вариант отображения`}</h3>
    <p>{`У компонента четыре варианта отображения: `}<inlineCode parentName="p">{`primary`}</inlineCode>{`, `}<inlineCode parentName="p">{`secondary`}</inlineCode>{`, `}<inlineCode parentName="p">{`secondaryInvert`}</inlineCode>{`, `}<inlineCode parentName="p">{`tertiary`}</inlineCode>{`. Вариант отображения задается пропом `}<inlineCode parentName="p">{`variant`}</inlineCode>{`.`}</p>
    <p><inlineCode parentName="p">{`primary`}</inlineCode>{` – вариант, используемый по умолчанию. Рекомендуем использовать в большом количестве текста`}</p>
    <p><inlineCode parentName="p">{`secondary`}</inlineCode>{` – данный вариант рекомендуется использовать самостоятельно, отдельно от текста`}</p>
    <p><inlineCode parentName="p">{`secondaryInvert`}</inlineCode>{` – рекомендуется использовать по тому же принципу, что и `}<inlineCode parentName="p">{`secondary`}</inlineCode>{`, только на цветном фоне`}</p>
    <Playground __position={4} __code={'<>\n  <div\n    style={{\n      padding: spacingSmall2X,\n    }}\n  >\n    <Link\n      variant={LinkVariant.primary}\n      href=\"https://www.hse.ru/\"\n      target=\"_blank\"\n    >\n      Национальный исследовательский университет «Высшая школа экономики»\n    </Link>\n  </div>\n  <Space size={Size.small_2x} vertical />\n  <div\n    style={{\n      padding: spacingSmall2X,\n    }}\n  >\n    <Link\n      variant={LinkVariant.secondary}\n      href=\"https://www.hse.ru/\"\n      target=\"_blank\"\n    >\n      Национальный исследовательский университет «Высшая школа экономики»\n    </Link>\n  </div>\n  <Space size={Size.small_2x} vertical />\n  <div\n    style={{\n      padding: spacingSmall2X,\n      background: colorBrandDark,\n    }}\n  >\n    <Link\n      variant={LinkVariant.secondaryInvert}\n      href=\"https://www.hse.ru/\"\n      target=\"_blank\"\n    >\n      Национальный исследовательский университет «Высшая школа экономики»\n    </Link>\n  </div>\n  <Space size={Size.small_2x} vertical />\n  <Link variant={Link.Variant.tertiary} href={\'#\'}>\n    Пункт меню\n  </Link>\n</>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      Link,
      LinkVariant,
      Size,
      Space,
      FigmaEmbed,
      ComponentLinks,
      P,
      colorBrandDark,
      spacingSmall2X,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <>
    <div style={{
          padding: spacingSmall2X
        }}>
      <Link variant={LinkVariant.primary} href="https://www.hse.ru/" target="_blank" mdxType="Link">
        Национальный исследовательский университет «Высшая школа экономики»
      </Link>
    </div>
    <Space size={Size.small_2x} vertical mdxType="Space" />
    <div style={{
          padding: spacingSmall2X
        }}>
      <Link variant={LinkVariant.secondary} href="https://www.hse.ru/" target="_blank" mdxType="Link">
        Национальный исследовательский университет «Высшая школа экономики»
      </Link>
    </div>
    <Space size={Size.small_2x} vertical mdxType="Space" />
    <div style={{
          padding: spacingSmall2X,
          background: colorBrandDark
        }}>
      <Link variant={LinkVariant.secondaryInvert} href="https://www.hse.ru/" target="_blank" mdxType="Link">
        Национальный исследовательский университет «Высшая школа экономики»
      </Link>
    </div>
    <Space size={Size.small_2x} vertical mdxType="Space" />
    <Link variant={Link.Variant.tertiary} href={'#'} mdxType="Link">
      Пункт меню
    </Link>
  </>
    </Playground>
    <h3 {...{
      "id": "подчеркнутая-ссылка"
    }}>{`Подчеркнутая ссылка`}</h3>
    <p><inlineCode parentName="p">{`secondary`}</inlineCode>{` и `}<inlineCode parentName="p">{`secondaryInvert`}</inlineCode>{` ссылки можно подчеркнуть, передав проп `}<inlineCode parentName="p">{`underlined`}</inlineCode></p>
    <Playground __position={5} __code={'<>\n  <div style={{ padding: spacingSmall2X }}>\n    <Link\n      variant={LinkVariant.secondary}\n      href=\"https://www.hse.ru/\"\n      target=\"_blank\"\n      underlined\n    >\n      Национальный исследовательский университет «Высшая школа экономики»\n    </Link>\n  </div>\n  <Space size={Size.small} />\n  <div\n    style={{\n      padding: spacingSmall2X,\n      background: colorBrandDark,\n    }}\n  >\n    <Link\n      variant={LinkVariant.secondaryInvert}\n      href=\"https://www.hse.ru/\"\n      target=\"_blank\"\n      underlined\n    >\n      Национальный исследовательский университет «Высшая школа экономики»\n    </Link>\n  </div>\n</>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      Link,
      LinkVariant,
      Size,
      Space,
      FigmaEmbed,
      ComponentLinks,
      P,
      colorBrandDark,
      spacingSmall2X,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <>
    <div style={{
          padding: spacingSmall2X
        }}>
      <Link variant={LinkVariant.secondary} href="https://www.hse.ru/" target="_blank" underlined mdxType="Link">
        Национальный исследовательский университет «Высшая школа экономики»
      </Link>
    </div>
    <Space size={Size.small} mdxType="Space" />
    <div style={{
          padding: spacingSmall2X,
          background: colorBrandDark
        }}>
      <Link variant={LinkVariant.secondaryInvert} href="https://www.hse.ru/" target="_blank" underlined mdxType="Link">
        Национальный исследовательский университет «Высшая школа экономики»
      </Link>
    </div>
  </>
    </Playground>
    <h3 {...{
      "id": "неактивная-ссылка"
    }}>{`Неактивная ссылка`}</h3>
    <p>{`Ссылку можно сделать неактивной, передав проп `}<inlineCode parentName="p">{`inactive`}</inlineCode>{`. Такая ссылка становится недоступной для действий пользователя`}</p>
    <Playground __position={6} __code={'<Link\n  variant={LinkVariant.primary}\n  href=\"https://www.hse.ru/\"\n  target=\"_blank\"\n  inactive\n>\n  Национальный исследовательский университет «Высшая школа экономики»\n</Link>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      Link,
      LinkVariant,
      Size,
      Space,
      FigmaEmbed,
      ComponentLinks,
      P,
      colorBrandDark,
      spacingSmall2X,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Link variant={LinkVariant.primary} href="https://www.hse.ru/" target="_blank" inactive mdxType="Link">
    Национальный исследовательский университет «Высшая школа экономики»
  </Link>
    </Playground>
    <h3 {...{
      "id": "выделение-посещенных-ссылок"
    }}>{`Выделение посещенных ссылок`}</h3>
    <p>{`По умолчанию посещенные ссылки никак не выделяются. Для изменения данного поведения передайте проп `}<inlineCode parentName="p">{`showVisited`}</inlineCode></p>
    <Playground __position={7} __code={'<>\n  <div style={{ padding: spacingSmall2X }}>\n    <Link target=\"_blank\" href=\"https://www.hse.ru/\" showVisited={true}>\n      Национальный исследовательский университет «Высшая школа экономики»\n    </Link>\n  </div>\n  <Space size={Size.small} vertical />\n  <div style={{ padding: spacingSmall2X }}>\n    <Link\n      target=\"_blank\"\n      href=\"https://www.hse.ru/\"\n      showVisited={true}\n      variant={LinkVariant.secondary}\n    >\n      Национальный исследовательский университет «Высшая школа экономики»\n    </Link>\n  </div>\n  <Space size={Size.small} vertical />\n  <div\n    style={{\n      padding: spacingSmall2X,\n      background: colorBrandDark,\n    }}\n  >\n    <Link\n      target=\"_blank\"\n      href=\"https://www.hse.ru/\"\n      showVisited={true}\n      variant={LinkVariant.secondaryInvert}\n    >\n      Национальный исследовательский университет «Высшая школа экономики»\n    </Link>\n  </div>\n</>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      Link,
      LinkVariant,
      Size,
      Space,
      FigmaEmbed,
      ComponentLinks,
      P,
      colorBrandDark,
      spacingSmall2X,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <>
    <div style={{
          padding: spacingSmall2X
        }}>
      <Link target='_blank' href='https://www.hse.ru/' showVisited={true} mdxType="Link">
        Национальный исследовательский университет «Высшая школа экономики»
      </Link>
    </div>
    <Space size={Size.small} vertical mdxType="Space" />
    <div style={{
          padding: spacingSmall2X
        }}>
      <Link target='_blank' href='https://www.hse.ru/' showVisited={true} variant={LinkVariant.secondary} mdxType="Link">
        Национальный исследовательский университет «Высшая школа экономики»
      </Link>
    </div>
    <Space size={Size.small} vertical mdxType="Space" />
    <div style={{
          padding: spacingSmall2X,
          background: colorBrandDark
        }}>
      <Link target='_blank' href='https://www.hse.ru/' showVisited={true} variant={LinkVariant.secondaryInvert} mdxType="Link">
        Национальный исследовательский университет «Высшая школа экономики»
      </Link>
    </div>
  </>
    </Playground>
    <h3 {...{
      "id": "текстовые-свойства"
    }}>{`Текстовые свойства`}</h3>
    <p>{`По умолчанию Link наследует текстовые стили.
Чтобы использовать компонент Link со своими текстовыми стилями, передайте ему проп `}<inlineCode parentName="p">{`standalone`}</inlineCode>{`.`}</p>
    <Playground __position={8} __code={'<>\n  <p className=\"hse-Text_paragraph-small\">\n    Small paragraph with a <Link standalone>link</Link> which is too big\n  </p>\n  <p className=\"hse-Text_paragraph-small\">\n    Small paragraph with a <Link>link</Link> which inherits its size\n  </p>\n</>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      Link,
      LinkVariant,
      Size,
      Space,
      FigmaEmbed,
      ComponentLinks,
      P,
      colorBrandDark,
      spacingSmall2X,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <>
    <p className="hse-Text_paragraph-small">
      Small paragraph with a <Link standalone mdxType="Link">link</Link> which is too big
    </p>
    <p className="hse-Text_paragraph-small">
      Small paragraph with a <Link mdxType="Link">link</Link> which inherits its size
    </p>
  </>
    </Playground>
    <h3 {...{
      "id": "контролируемый-фокус"
    }}>{`Контролируемый фокус`}</h3>
    <p>{`С помощью пропа `}<inlineCode parentName="p">{`focused`}</inlineCode>{` можно контролировать сфокусированное состояние ссылки`}</p>
    <Playground __position={9} __code={'() => {\n  const [focused, setFocused] = useState(false)\n  return (\n    <Link\n      focused={focused}\n      href={\'#\'}\n      onClick={e => {\n        e.preventDefault()\n        setFocused(f => !f)\n      }}\n    >\n      Click on me\n    </Link>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      Link,
      LinkVariant,
      Size,
      Space,
      FigmaEmbed,
      ComponentLinks,
      P,
      colorBrandDark,
      spacingSmall2X,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
      {() => {
        const [focused, setFocused] = useState(false);
        return <Link focused={focused} href={'#'} onClick={e => {
          e.preventDefault();
          setFocused(f => !f);
        }} mdxType="Link">
      Click on me
    </Link>;
      }}
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={Link} mdxType="Props" />
    <p>{`Все остальные свойства пробрасываются на корневой элемент в соответствии с пропом `}<inlineCode parentName="p">{`component`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "css-классы"
    }}>{`CSS классы`}</h2>
    <p>{`Вы можете использовать данный компонент без библиотеки компонентов, а лишь подключив css стили. Ниже приведен пример использования. Компоненты сверстаны по методологии `}<a parentName="p" {...{
        "href": "https://bem.info/"
      }}>{`BEM`}</a>{`: миксуя необходимые модификаторы вы можете задать компоненту определенное состояние.`}</p>
    <p>{`Класс блока: `}<inlineCode parentName="p">{`hse-Link`}</inlineCode></p>
    <h3 {...{
      "id": "модификаторы"
    }}>{`Модификаторы`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Модификатор`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Описание`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Link_variant_primary`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Делает ссылку первичной (с вариантом primary)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Link_variant_secondary`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Делает ссылку вторичной (с вариантом secondary)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Link_inactive`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Делает ссылку неактивной`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "элементы"
    }}>{`Элементы`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Элемент`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Описание`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Link__left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Слот слева для иконки`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Link__right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Слот справа для иконки`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "пример-использования"
    }}>{`Пример использования`}</h3>
    <Playground __position={11} __code={'<a className={\'hse-Link hse-Link_variant_primary\'}>\n  <span className={\'hse-Link__left\'}>\n    <svg\n      width=\"16\"\n      height=\"16\"\n      viewBox=\"0 0 16 16\"\n      fill=\"none\"\n      xmlns=\"http://www.w3.org/2000/svg\"\n    >\n      <path\n        d=\"M14 7H6L9.5 3.5L8 2L2 8L8 14L9.5 12.5L6 9H14V7Z\"\n        fill=\"currentColor\"\n      />\n    </svg>\n  </span>\n  HSE\n</a>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      Link,
      LinkVariant,
      Size,
      Space,
      FigmaEmbed,
      ComponentLinks,
      P,
      colorBrandDark,
      spacingSmall2X,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
	<a className={'hse-Link hse-Link_variant_primary'}>
	  <span className={'hse-Link__left'}>
	    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 7H6L9.5 3.5L8 2L2 8L8 14L9.5 12.5L6 9H14V7Z" fill="currentColor" />
        </svg>
	  </span>
	  HSE
	</a>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      